<template>
    <div class="promotionGoods">
        <!-- 搜索 -->
        <div class="searchdatas">
            <el-form :inline="true" class="demo-form-inline">
                 <el-form-item>
			      <el-select v-model="searchData.queryConditionType"
			                 style="width: 120px"> 
			        <el-option
			          v-for="item in GoodsTypes"
			          :disabled="item.disabled"
			          :key="item.id"
			          :label="item.name"
			          :value="item.id">
			        </el-option>
			      </el-select>
			    </el-form-item>

          <el-form-item>
			      <el-input class=""
			                placeholder="搜索关键字"
			                prefix-icon="el-icon-search"
			                v-model="searchData.goodsName"
			                style="width: 260px">
			      </el-input>
			    </el-form-item>

                <el-form-item>
			      <el-select v-model="searchData.queryType"
			                 style="width: 120px"> 
			        <el-option
			          v-for="item in PromotionTypes"
			          :disabled="item.disabled"
			          :key="item.id"
			          :label="item.name"
			          :value="item.id">
			        </el-option>
			      </el-select>
			    </el-form-item>

                <el-form-item>
			      <el-button type="primary"  @click="search">查询</el-button>
                  <el-button @click="downloadExcel" icon="el-icon-download">导出</el-button>
                  <el-button type="primary" @click="$router.push('/promotionSet')" style="margin-left:20px">我要推广</el-button>
			    </el-form-item>
            </el-form>
        </div>

        <!-- 表格 -->
        <div class="tablebox">
              <el-table
               :data="tableData"
               style="width: 100%">
               <el-table-column
                 label="封面图"
                 prop="cover"
                 width="120">
                <template slot-scope="scope">
                <img :src="scope.row.cover" alt="" class="table-row-image-60"/>
                </template>
               </el-table-column>
                <el-table-column
                 label="商品名称"
                 prop="goodsName"
                 width="150">
               </el-table-column>
                <el-table-column
                 label="开始推广时间"
                 prop="startTime"
                 width="180">
                 <template slot-scope="scope">
                   <span>{{formatTime(scope.row.startTime)}}</span>
                 </template>
               </el-table-column>
                <el-table-column
                 label="计划结束时间"
                 prop="endTime"
                 width="180">
                <template slot-scope="scope">
                   <span>{{formatTime(scope.row.endTime)}}</span>
                 </template>
               </el-table-column>
                <el-table-column
                 label="原售价"
                 prop="price"
                 width="100">
               </el-table-column>
                <el-table-column
                 label="推广库存"
                 prop="extensionStockNum"
                 width="100">
                  <template slot-scope="scope">
                  <span v-if="scope.row.extensionStockNum >0">{{scope.row.extensionStockNum}}</span>
                  <span v-else-if="scope.row.extensionStockNum <=0">已售罄</span>
                 </template>
               </el-table-column>
                <el-table-column
                 label="推广费比例(%)"
                 prop="extensionFeeProportion"
                 width="120">
               </el-table-column>
                <el-table-column
                 label="推广费"
                 prop="extensionFee"
                 width="120">
               </el-table-column>
                <el-table-column
                 label="推广价"
                 prop="extensionPrice"
                 width="120">
               </el-table-column>
                <el-table-column
                 label="状态"
                 prop="status"
                 width="120">
                  <template slot-scope="scope">
                   <span>{{scope.row.status == 1? '推广中' :scope.row.status == 2? '未开始' :scope.row.status == 3? '已失效':''}}</span>
                 </template>
               </el-table-column>
               <el-table-column label="操作">
                 <template slot-scope="scope">
                   <el-button
                     size="mini"
                     type="primary"
                     @click="PromotionNum(scope.$index, scope.row)">推广统计</el-button>
                   <el-button
                     size="mini"
                     type="primary"
                     @click="editNum(scope.$index, scope.row)">编辑</el-button>
                    <el-button
                     v-if="scope.row.status != 3"
                     size="mini"
                     type="warning"
                     @click="notice(scope.$index, scope.row)">失效</el-button>
                     <el-button
                     v-if="scope.row.status == 3"
                     size="mini"
                     type="warning"
                     @click="suredel(scope.$index, scope.row)">删除</el-button>
                 </template>
               </el-table-column>
             </el-table>
        </div>

        <!-- 推广数据 -->
        <div class="PromotionNum">
          <el-dialog
              title="推广数据"
              :visible.sync="NumdialogVisible"
              width="25%"
              center
             >
              <div class="numlist">
                 <div class="numoption">
                    <p>支付商品件数</p>
                    <p>{{payGoodsNum}}</p>
                 </div>
                 <div class="numoption">
                    <p>支付订单总额（元）</p>
                    <p>{{payFee}}</p>
                 </div>
                 <div class="numoption">
                    <p>已发放推广费总额</p>
                    <p>{{settledPromotionFee}}</p>
                 </div>
              </div>
              <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="NumdialogVisible = false">确 定</el-button>
              </span>
            </el-dialog>
        </div>

        <!-- 编辑 -->
        <div class="editNum">
             <el-dialog
              title="修改数据"
              :visible.sync="editdialogVisible"
              width="30%"
              center
             >
               <el-form class="demo-form-inline" :model="form" ref="ruleForm" >
                  <el-form-item  prop="startTime" v-if="this.status != 1">
                       <p>推广开始时间</p>
                       <el-date-picker
                          v-model="form.startTime"
                          type="date"
                          placeholder="选择日期"
                          format="yyyy 年 MM 月 dd 日"
                          :disabled="disabledTime"
                          :picker-options="pickerOptions"
                          value-format="timestamp">
                        </el-date-picker>
                  </el-form-item>
                  <el-form-item  prop="endTime">
                       <p>推广结束时间</p>
                       <el-date-picker
                          v-model="form.endTime"
                          type="date"
                          placeholder="选择日期"
                          format="yyyy 年 MM 月 dd 日"
                          :disabled="disabledTime"
                          :picker-options="pickerOptions"
                          value-format="timestamp">
                        </el-date-picker>
                  </el-form-item>

                  <el-form-item prop="extensionStockNum">
                    <p>推广库存</p>
                     <el-input v-model.number="form.extensionStockNum" placeholder="请输入数据" @change="editStock" type="number"></el-input>
                  </el-form-item>

                   <el-form-item prop="extensionFeeProportion">
                    <p>推广比例</p>
                    <el-input v-model.number="form.extensionFeeProportion" placeholder="请输入数据" @change="editProportion"  type="number"></el-input>
                  </el-form-item> 

                  <el-form-item prop="extensionFee">
                    <p>推广费</p>
                     <el-input v-model="form.extensionFee" placeholder="请输入数据" @change="editPromotionfee"  type="number"></el-input>
                  </el-form-item>

               </el-form>

              <span slot="footer" class="dialog-footer">
                <el-button @click="editdialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="editPromotionGoods('ruleForm')">确 定</el-button>
              </span>
            </el-dialog>
        </div>

        <!-- 失效 -->
            <div class="notice">
             <el-dialog
               title="确认失效"
              :visible.sync="noticedialogVisible"
               width="25%"
               center
               >
              <span class="tips">推广失效后将不再进行推广，是否确认失效</span>
              <span slot="footer" class="dialog-footer">
                <el-button @click="noticedialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="confirmDel">确 定</el-button>
              </span>
            </el-dialog>
        </div>

               <!-- 删除 -->
        <div class="notice">
             <el-dialog
               title="确认删除"
              :visible.sync="deldialogVisible"
               width="25%"
               center
               >
              <span class="tips">确定要删除商品吗?</span>
              <span slot="footer" class="dialog-footer">
                <el-button @click="deldialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="insureDel">确 定</el-button>
              </span>
            </el-dialog>
        </div>


        <!-- 分页 -->
        <div class="pageNo">
               <el-pagination
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                  :current-page="currentPage"
                  :page-sizes="[5, 10, 15, 20]"
                  :page-size="100"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="totalListNum">
              </el-pagination>
        </div>

        <excel :visible.sync="showDownloadExcel"></excel>
    </div>
</template>

<script>
 import {disposeSearchData,formatTime, isNumber} from "../../../assets/js/utils";
import {URL} from '../../../config/url.supplier'
import {plusDownloadExcelTask} from '../../../store/excel'
import excel from '../../../components/public/downloadExcel/downloadExcel.vue'
export default {
    name:'promotionGoods',
    components:{
      excel
    },
    data(){
        return{
            GoodsTypes:[
                { id:1,name:'商品名称或关键字'},
            ],
            PromotionTypes:[
                { id:0,name:"全部"},
                { id:1,name:'推广中'},
                { id:2,name:'未开始'},
                { id:3,name:'已失效'}
            ],
            searchData:{
                queryType:0,
                goodsId:'',
                goodsName:'',
                queryConditionType:1,
                searchValue:''
            },
            pageSize:5,
            currentPage:1,
            showDownloadExcel:false, //excel
            NumdialogVisible:false, //统计
            editdialogVisible:false, //编辑
            noticedialogVisible:false, //失效
            deldialogVisible:false,//删除
            tableData: [],
            totalListNum:0,
            settledPromotionFee:0, //	已发放推广费总额
            payFee:0,//支付订单金额
            payGoodsNum:0, //支付商品总数
            // 编辑商品
            form:{
                endTime:'', //结束时间
                extensionStockNum:'', //推广商品库存
                extensionFee:'',//推广费
                extensionFeeProportion:'',//推广比例
                startTime:'',//开始时间
            },
            editgoodsId:'',
            status:'',
            ids:'',//推广商品ID
            price:'',//商品售价
            id:'',
            goodsSkuId:'',
            stockNum:'', //推广中商品总库存
              // 时间配置-不准选当日之前的数据
            pickerOptions: {
                disabledDate(time) {
                 return time.getTime() < (Date.now() - 86400000);
                }
            },
          //   rules: {
          //      extensionStockNum: [
          //        { required: true, message: '请输入合法数字', trigger: 'blur', pattern:/^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/,},
          //      ],
          //      extensionFeeProportion: [
          //        { required: true, message: '请输入合法数字', trigger: 'blur', pattern:/^(?:[1-9]?\d|100)$/,},
          //      ],
          //      extensionFee: [
          //       { required: true, message: '请输入合法数字', trigger: 'blur', pattern:/^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/,},
          //     ]
          //  }

        }
    },
    created(){
      this.getPromotionGoods()

    },
    computed:{

      disabledTime(){
        // return ![0,1].includes(this.status);
      },

    },
    methods:{

        formatTime,

        //查询
          search(){
            this.currentPage1 = 1;
            this.getPromotionGoods()
        },

        // 推广统计
        PromotionNum(index,row){
          console.log("本行商品信息",index,row)
          this.NumdialogVisible = true
          this.settledPromotionFee = row.settledPromotionFee
          this.payFee = row.payFee
          this.payGoodsNum = row.payGoodsNum
          this.status = row.status
          this.editgoodsId = row.goodsId
        },

        // 编辑
        editNum(index,row){
           this.editdialogVisible = true
           this.editgoodsId = row.goodsId
           this.form.endTime = row.endTime
           this.form.startTime = row.startTime
           this.form.extensionStockNum = row.extensionStockNum
           this.form.extensionFeeProportion = row.extensionFeeProportion
           this.form.extensionFee = row.extensionFee
           this.price = row.price
           this.status = row.status
           this.id = row.id
           this.goodsSkuId = row.goodsSkuId
           this.stockNum = row.stockNum
         
        },

        //失效
        notice(index,row){
          this.noticedialogVisible = true
          this.id = row.id

          console.log("失效",row)
        },

        
        //删除
        suredel(index,row){
          this.deldialogVisible  = true
          this.id = row.id
          console.log("删除",row)
        },


        //修改库存
        editStock(extensionStockNum){
          if(extensionStockNum < 0){
               this.$message({
               message: '请输入大于0数字',
               type: 'warning'
              });
          return this.form.extensionStockNum = 0
          }else if(extensionStockNum == ''){
             this.$message({
               message: '请输入有效数字',
               type: 'warning'
              });
          return this.form.extensionStockNum = 0
          }else if(extensionStockNum > this.stockNum){
             this.$message({
               message: '推广库存不能大于总库存',
               type: 'warning'
              });
          return this.form.extensionStockNum = this.stockNum
          }
        },

        //修改比例
     editProportion(extensionFeeProportion){
          if(extensionFeeProportion < 0){
               this.$message({
               message: '请输入大于0数字',
               type: 'warning'
              });
          return this.form.extensionFeeProportion = 0
          }else if(extensionFeeProportion == ''){
             this.$message({
               message: '请输入有效数字',
               type: 'warning'
              });
           return this.form.extensionFeeProportion = 0
          }else if(extensionFeeProportion > 100){
             this.$message({
               message: '推广比例不能大于100',
               type: 'warning'
              });
          return this.form.extensionFeeProportion = 100
          }else{
              this.form.extensionFee = (this.price*extensionFeeProportion/100).toFixed(2)
          }

        },

        //修改推广费
        editPromotionfee(extensionFee){
          if(extensionFee < 0){
               this.$message({
               message: '请输入大于0数字',
               type: 'warning'
              });
          return this.form.extensionFee = 0
          }else if(extensionFee == ''){
             this.$message({
               message: '请输入有效数字',
               type: 'warning'
              });
           return this.form.extensionFee = 0
          }else{
             this.form.extensionFeeProportion = 100*(extensionFee/this.price).toFixed(2)
             console.log("修改比例",this.form.extensionFeeProportion)
          }
         
        },

        //确认失效
      
        confirmDel(){
          let ids = []
          ids.push(this.id)
          console.log('ids:',ids)
          let data = {
            ids,
            identifying:0
          };
          console.log('data:',data)
          console.log('222data:',this.qs.stringify(data))
          this.axios.get(URL.supplierPromotingGoods.deletePromotionGoods + `?ids=${ids}&identifying=0`
          // ,{params:data}
            ).then(res => {
              if (res.code === 0){   
                console.log("失效成功")
                this.noticedialogVisible = false
                this.getPromotionGoods()
               }
            }).catch(() => {
               console.log("失败")
            })
          
        },

        // 删除
       insureDel(){
          let ids = []
          ids.push(this.id)
          console.log('ids:',ids)
          let data = {
            ids,
            identifying:1
          };
             console.log('data:',data)
          this.axios.get(URL.supplierPromotingGoods.deletePromotionGoods + `?ids=${ids}&identifying=1`
          // ,{params:data}
            ).then(res => {
              if (res.code == 0){   
                // console.log("删除成功")
                this.deldialogVisible = false
                this.getPromotionGoods()
               }
            }).catch(() => {
               console.log("删除失败")
            })
          
        },


        //搜索参数
           jointSearchData() {
            let data = {
              pageNo: this.currentPage,
              pageSize: this.pageSize,
            };
            data = Object.assign(data, this.searchData);
           disposeSearchData(data);
           data.status === 0 && delete data.status;
           return data;
            },

        //获取商品推广列表
        getPromotionGoods(){
            let data = this.jointSearchData();
            this.axios.get(URL.supplierPromotingGoods.listOfPromotionGoods,{params:data}
            ).then(res => {
              if (res.code === 0){
               this.tableData = res.data.records
               this.totalListNum = parseInt(res.data.total)
               console.log("表格数据", this.tableData)
               }
            }).catch(() => {
               console.log("获取失败")
            })
        },

        //编辑推广商品
       editPromotionGoods(formName) {
         
        this.$refs[formName].validate((valid) => {
           console.log('valid:',valid)
          console.log("222")
          if (valid) {
           
            console.log('11111')
            let data = {
             	endTime:this.form.endTime.toString(),
            	extensionFeeProportion:Number(this.form.extensionFeeProportion),
            	extensionStockNum:parseInt(this.form.extensionStockNum),
            	goodsId:this.editgoodsId,
            	startTime:this.form.startTime.toString(),
              id:this.id,
              goodsSkuId:this.goodsSkuId
          };
          this.axios.post(URL.supplierPromotingGoods.updatePromotionAdmin,data
            ).then(res => {
              if (res.code === 0){
                  console.log("修改成功")
                  this.editdialogVisible = false
                  this.getPromotionGoods()
               }
            }).catch(() => {
               console.log("修改失败")
            })
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },


         //导出excel
         downloadExcel(){
         let searchData = this.jointSearchData();
          let data = {
          name:'推广带货导出报表',
          params:{
            excelType:12,
            excelParameterJson:JSON.stringify(searchData),
          },
        };
           plusDownloadExcelTask.call(this,data);
        },

        //分页
         handleSizeChange(val) {
          console.log(`每页 ${val} 条`);
          this.currentPage = 1;
          this.pageSize = val;
          this.getPromotionGoods()
         },

        handleCurrentChange(val) {
          console.log(`当前页: ${val}`);
          this.currentPage = val;
          this.getPromotionGoods()
        }
    },


}
</script>

<style lang="less" scoped>

  .promotionGoods{
    background: #fff;
    padding:10px;
    .tips{
      text-align: center;
    }
    .searchdatas{
        display: flex;
        width: 100%;
        height:50px;
        margin: 20px;
    }
    .tablebox{
       margin: 0 20px 0 20px;
    }
    .pageNo{
        width: 100%;
        margin-top: 20px;
        display: flex;
        justify-content: center;
    }
    .editNum{
     form{
       width: 70%;
       margin: 0 auto;
       .el-input__inner{
         width: 375px;
       }
       .el-form-item{
        margin-bottom:0px;
       }
       p{
         margin: 0;
       }
     }
    }
     .PromotionNum{
       .numlist{
         display: flex;
         flex-wrap: wrap;
         width:85%;
         margin: 0 auto ;
         .numoption{
            width:180px;
            text-align: center;
            p:nth-child(2){
              margin-top:0px;
              color: #07a675;
              font-weight: bold;
            }
         }
       }
    }

  }

</style>